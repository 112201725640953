@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 100%;
    min-height: 100%;
}

#root {
    height: 100%;
}